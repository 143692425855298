import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import SEO from '../components/seo'
import ArticleCards from '../components/articlecards'

class ArticleTemplate extends Component {
  render() {
    let { article } = this.props.data

    return (
      <>
        <SEO title={he.decode(article.yoast_meta.yoast_wpseo_title)} description={article.yoast_meta.yoast_wpseo_metadesc} />
        <section className="article">
          <div className="article__inner container">
            <Fade bottom distance='40px' cascade>

              <div className="article__innertop">
                <picture className='article__image'>
                  { article.acf.image && <img src={article.acf.image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
                </picture>
                <p className="datetime">{article.date}</p>
                <h1 className="title">{article.title}</h1>
              </div>

              <div className="article__innerbottom">
                <div className="article__innerright">
                  <div className="article__content">
                    <p className="title">
                      {article.acf.subtitle}
                    </p>
                    <div className="content" dangerouslySetInnerHTML={{ __html: article.acf.content }} />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <ArticleCards />
        </section>
      </>
    )
  }
}

export const newsQuery = graphql`
  query ($id: String!) {
    article: wordpressPost(id: {eq: $id}) {
      title
      slug
      date(formatString: "DD/MM/YY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        subtitle
        content
        excerpt
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
