import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class ArticleCard extends Component {

  render() {
    let data = this.props;
    return (
      <Fade bottom distance='40px'>
        <Link to={data.slug} className="imagearticlecard__item">
          { data.acf.image && <img src={data.acf.image.localFile.childImageSharp.original.src} alt={data.title} /> }
          <div className="imagearticlecard__content">
            <p className="datetime">
              {data.date}
            </p>
            <h4 className="title">{ data.title }</h4>
            { data.acf.subtitle &&
              <p className="subheading">
                {data.acf.subtitle}
              </p>
            }
            { data.acf.excerpt &&
              <div className="body" dangerouslySetInnerHTML={{ __html: data.acf.excerpt }} />
            }
            <p className="link">Read more</p>
          </div>
        </Link>
      </Fade>
    )
  }
}

export default ArticleCard
